import styled from "styled-components"
import { transparentize } from "polished"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 7.2rem ${({ theme }) => theme.layout.mobilePadding} 0;
  height: 100vh;
`
const StyledMapWrapper = styled.div`
  margin-left: -1.6rem;
  position: relative;
  width: 100vw;
  max-width: ${({ theme }) => theme.gridWidth};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledProjectBlockWrapper = styled.div`
  padding: 0.8rem 1.6rem;
  background-color: ${({ theme }) => theme.color.white};
  width: 100%;
  width: 41.6rem;
  height: 12.8rem;
  box-shadow: 0px 0.2rem 0.4rem 0px
    ${({ theme }) => transparentize(0.88, theme.color.main01)};

  ${({ theme }) => theme.mq.medium} {
    padding: 2.4rem;
    transform: none;
    left: initial;
    left: 2.4rem;
    top: 9.6rem;
    height: 26.8rem;
  }
`
export { StyledWrapper, StyledMapWrapper, StyledProjectBlockWrapper }
