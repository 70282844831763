import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioMap from "components/content/Portfolio/PortfolioMap"

const PortfolioMapPage = ({
  pageContext: { language },
  data: {
    api: { projects },
  },
}) => (
  <Layout language={language}>
    <SEO title="Portfolio" />
    <PortfolioMap projects={projects} />
  </Layout>
)

PortfolioMapPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    api: PropTypes.shape({
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          overview: PropTypes.shape({
            status: PropTypes.string,
            city: PropTypes.shape({
              name: PropTypes.string,
              stateCode: PropTypes.string,
            }),
          }),
          primaryPropertyTag: PropTypes.string,
          projectTag: PropTypes.string,
          id: PropTypes.string,
          bannerImages: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.oneOfType([
                PropTypes.shape({
                  original: PropTypes.string,
                  large: PropTypes.string,
                  medium: PropTypes.string,
                  small: PropTypes.string,
                }),
                PropTypes.string,
              ]),
              id: PropTypes.string,
            })
          ),
          projectPosition: PropTypes.shape({
            point: PropTypes.shape({
              type: PropTypes.string,
              coordinates: PropTypes.arrayOf(PropTypes.number),
            }),
          }),
          name: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
}

export default PortfolioMapPage

export const query = graphql`
  query PortfolioMapPageData($apiLocale: API_Locale) {
    api {
      projects(language: $apiLocale) {
        overview {
          status
          city {
            name
            stateCode
          }
        }
        primaryPropertyTag
        projectTag
        id
        slug
        bannerImages {
          image
          id
        }
        name
        projectPosition {
          point
        }
      }
    }
  }
`
