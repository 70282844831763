/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby-plugin-intl"

import filterProjects from "utils/filterProjects"
import paths from "utils/paths"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import PortfolioHeader from "components/molecules/PortfolioHeader"
import ProjectBlock from "components/molecules/ProjectBlock"
import Map from "components/molecules/Map"
import {
  StyledWrapper,
  StyledMapWrapper,
  StyledProjectBlockWrapper,
} from "./PortfolioMap.styles"

const PortfolioMap = ({ projects }) => {
  const [searchFilters, setSearchFilters] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [filteredProjects, setFilteredProjects] = useState([])
  const { portfolio, portfolioProjectSnapshot } = paths

  useEffect(() => {
    if (searchFilters) {
      const projectsToDisplay = filterProjects(
        searchFilters,
        projects.filter((project) => project.projectPosition)
      ).map((project) => ({
        id: project.id,
        ...project.projectPosition.point,
        onInfoBoxClick: () => {
          navigate(portfolioProjectSnapshot(project.slug))
          trackCustomEventTrigger({
            category: `Project map`,
            action: "Click",
            label: `Project map link(${project.name})`,
          })
        },
        infoBoxContent: (
          <StyledProjectBlockWrapper>
            <ProjectBlock project={project} type="secondary" />
          </StyledProjectBlockWrapper>
        ),
      }))
      setFilteredProjects(projectsToDisplay)
      setSelectedProject(null)
    }
  }, [searchFilters])

  return (
    <StyledWrapper>
      <PortfolioHeader
        count={filteredProjects.length}
        onChange={setSearchFilters}
        icon={{ name: "grid", pathToNavigate: portfolio }}
        projects={projects.map((project) => ({
          label: project.name,
          value: project.slug,
        }))}
      />
      <StyledMapWrapper>
        <Map
          projects={filteredProjects}
          selectedId={selectedProject && selectedProject.id}
        />
      </StyledMapWrapper>
    </StyledWrapper>
  )
}

PortfolioMap.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      overview: PropTypes.shape({
        city: PropTypes.shape({
          name: PropTypes.string,
          stateCode: PropTypes.string,
        }),
      }),
      primaryPropertyTag: PropTypes.string,
      projectTag: PropTypes.string,
      id: PropTypes.string,
      bannerImages: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          id: PropTypes.string,
        })
      ),
      projectPosition: PropTypes.shape({
        point: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.number),
        }),
      }),
      name: PropTypes.string,
    })
  ).isRequired,
}
export default PortfolioMap
