class BouncingSubject {
  observers = []

  bounceStatus = false

  attach(observer) {
    this.observers.push(observer)
    observer(this.bounceStatus)
  }

  detach(observerToRemove) {
    this.observers.filter((observer) => observerToRemove !== observer)
  }

  notify(bounceStatus) {
    this.bounceStatus = bounceStatus
    this.observers.forEach((observer) => observer(bounceStatus))
  }
}

const bouncingSubject = new BouncingSubject()

export default bouncingSubject
