/* eslint-disable no-plusplus */
import React from "react"

import { StyledCubeGrid, StyledCube } from "./Spinner.styles"

const createCubes = () => {
  const cubes = []
  for (let i = 0; i < 9; i++) {
    cubes.push(<StyledCube key={i} />)
  }

  return cubes
}

const Spinner = () => (
  <StyledCubeGrid data-testid="spinner">{createCubes()}</StyledCubeGrid>
)

export default Spinner
