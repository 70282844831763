import styled, { keyframes } from "styled-components"

const gridScaleAnimation = keyframes`
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  } 35% {
    transform: scale3D(0, 0, 1);
  }
`

const StyledCubeGrid = styled.div`
  height: 4rem;
  width: 4rem;
  margin: 1rem;
`
const StyledCube = styled.div`
  width: 33%;
  height: 33%;
  background-color: ${({ theme }) => theme.color.main03};
  float: left;
  animation: ${gridScaleAnimation} 1.3s infinite ease-in-out;

  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(9) {
    animation-delay: 0.2s;
  }

  &:nth-child(2),
  &:nth-child(6) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  &:nth-child(4),
  &:nth-child(8) {
    animation-delay: 0.1s;
  }

  &:nth-child(7) {
    animation-delay: 0s;
  }
`

export { StyledCubeGrid, StyledCube }
